export default defineNuxtRouteMiddleware(async (to, _from) => {
    const client = useSupabaseClient()

    const session = await client.auth.getSession()

    if (!session.data.session) {
        return navigateTo(
            Urls.Auth({ redirectAfter: encodeURIComponent(to.fullPath) })
        )
    }
})
